import { FaMapMarkerAlt, FaClock, FaPhone } from 'react-icons/fa';

import MapComponent from './MapComponent';

const pink = '#ff99cc';

function Contacts() {
    return (
        <section className="contacts" id="contacts">
            <div className="info container">
                <h2 className="info-title">
                    Kontakt
                </h2>

                <div className="info-text">
                    <div className="info-text-item">
                        <h3 className="info-text-title">Adres Salona:</h3>
                        <div>
                            <div className="info-text-paragraph">
                                <a
                                    href="https://www.google.com/maps/search/?api=1&query=Biskupice+2,+32-200,+Miechów"
                                    className="header-top-link"
                                    title="Zobacz naszą lokalizację na Google Maps"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaMapMarkerAlt size={18} color={pink} />
                                    <span className="info-text-paragraph-content">Biskupice 2, 32-200, Miechów</span>
                                </a>
                            </div>
                            <div className="info-text-paragraph">
                                <FaClock size={18} color={pink} />
                                <span className="info-text-paragraph-content">Godziny otwarcia: pon-sob | 09:00-21:00</span>
                            </div>
                        </div>
                    </div>
                    <div className="info-text-item">
                        <h3 className="info-text-title">SKONTAKTUJ SIĘ Z NAMI:</h3>
                        <div>
                            <div className="info-text-paragraph">
                                <a
                                    href="tel:+48783796995"
                                    className="header-top-link"
                                    title="Zadzwoń do nas teraz"
                                >
                                    <FaPhone size={18} color={pink} />
                                    <span className="info-text-paragraph-content">(+48) 783 796 995</span>
                                </a>
                            </div>
                            {/* <div className="info-text-paragraph">
                                <FaEnvelope size={18} color={pink} />
                                <span className="info-text-paragraph-content">zoosalonsbp@mail.ru</span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <MapComponent />
        </section>
    );
}

export default Contacts;
